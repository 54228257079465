import { FC, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import useFetch from "src/hooks/useFetch";
import { AuthContext } from "src/context/authContext";
import { ToastContext } from "src/context/toastContext";
import { LoaderContext } from "src/context/loaderContext";

import { ACCEPTED_FILE_EXTENSIONS } from "src/constants";
import { DocumentAPIError, DocumentAPIResponse, DocumentUploadProps, FetchDocumentError, FetchDocumentResponseType, UploadedDocumentsListing } from "./types";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastTypes } from "src/types";
import { downloadFileAccordingToExtension } from "src/helpers/downloadFile";



const DocumentUpload: FC<DocumentUploadProps> = ({ caseId, caseDocuments, setNotes }): JSX.Element => {
  const [caseFile, changeCaseFile] = useState<FileList | null>(null);
  const [fileName, changeFileName] = useState<string>("");
  const [caseDocumentsList, changeCaseDocumentsListing] = useState<UploadedDocumentsListing[]>(caseDocuments);

  const ref = useRef<HTMLInputElement>(null);
  const { destroySession } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();

  const reset = () => {
    changeCaseFile(null);
    if (ref && ref.current) {
      ref.current.value = ""
    }
  };


  const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25 MB in bytes

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files;
    if (!selectedFile || selectedFile.length === 0) {
      reset();
      return;
    }
    if (selectedFile && selectedFile[0].size > MAX_FILE_SIZE) {
      showToast([`File size should not exceed 25 MB`], ToastTypes.ERROR);
      changeCaseFile(null);
      
      // Reset the file input field
      if (ref.current) {
        ref.current.value = "";
      }
    } else {
      changeCaseFile(selectedFile);
    }
  };


  const { loading: uploadFileLoading, error: uploadFileError, data: uploadFileResponse, mutate: uploadCaseDocument } =
    useFetch<DocumentAPIResponse>(APIEndpoints.UPLOAD_CASE_FILE);

  useEffect(() => {
    if (uploadFileError){
      hideLoader();
      changeCaseFile(null);
    }
    if (uploadFileResponse) {
      hideLoader();
      const { message, data } = uploadFileResponse;
      setNotes((prevNotes) => [data.note, ...prevNotes]);
      changeCaseDocumentsListing((prevList) => [
        ...prevList,
        {
          ...data,
          created_at: data.note.created_at,
          type: "",
          id: ""
        }
      ]);
      showToast([message], ToastTypes.SUCCESS);
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFileResponse, uploadFileError]);

  const uploadForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (caseFile) {
      const caseFileExt = `.${caseFile[0].name.split(".").pop()}`;
      const isExtAccepted = Object.keys(ACCEPTED_FILE_EXTENSIONS).find((ext) => caseFileExt === ext);
      if (typeof isExtAccepted === "string") {
        setLoader();
        const formData = new FormData();
        formData.append('file', caseFile[0]);
        uploadCaseDocument(formData, { caseId });
      } else {
        showToast(
          [`Not an acceptable format. The accepted formats are ${Object.keys(ACCEPTED_FILE_EXTENSIONS).toString()}`],
          ToastTypes.ERROR
        );
      }
    }
  };





  const { data: fetchDocumentData, error: fetchDocumentError, mutate: fetchDocument } =
    useFetch<FetchDocumentResponseType>(APIEndpoints.FETCH_DOCUMENT, {}, {}, false);

  useEffect(() => {
    if (fetchDocumentData || fetchDocumentError) {
      hideLoader();
      if(fetchDocumentData){
        const { data } = fetchDocumentData;
        downloadFileAccordingToExtension(data, fileName);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDocumentData, fetchDocumentError]);

  const showFetchedFile = (blobId: number, fileName: string,fetchType:string,idNumber:string|null) => {
    setLoader();
    changeFileName(fileName);
    if (fetchType === 'legacy') {
      fetchDocument({}, {}, { type: fetchType, id: idNumber });
    } else {
      fetchDocument({}, {}, { blob_id: blobId, case_id: caseId ,type: fetchType || "fetch" });
    }
  };





  return (
    <div className="card m-2">
      <div className="card-header">
        <h4 className="text-black m-0">Documents</h4>
      </div>

      <div className="card-body">
        <div className="mb-3">
          <div className={`container-fluid case-notes-listing ${caseDocumentsList && caseDocumentsList.length > 0 ? 'mb-4' : ''}`}>
            {caseDocumentsList && caseDocumentsList.length > 0 ?
              caseDocumentsList.map((doc, index) => (
                <div className={`row py-1 align-items-center ${index % 2 === 0 ? 'bg-secondary-subtle' : ''}`} key={doc.blob_id}>
                  <div className="col-7">
                    <button
                      type="button"
                      onClick={() => showFetchedFile(doc.blob_id, doc.filename, doc.type, doc.id)}
                      className="btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover p-0 align-baseline fs-6 text-start text-break"
                    >
                      {doc ? doc.filename : ''}
                    </button>
                  </div>
                  <div className="col-2 text-start">
                    <p className="mb-0 fs-6">{doc ? `${doc.size_in_kb} kb` : ''}</p>
                  </div>
                  <div className="col-3">
                    <p className="mb-0 fs-6">
                      {doc ? doc.created_at : ""}
                    </p>
                  </div>
                </div>
              ))
            : null}
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h6 className="text-black mb-xxl-0 ">
              <i className="bi bi-cloud-arrow-up-fill fs-5 text-end"></i>{' '}
              Upload New Document
            </h6>
          </div>

          <div className="card-body">
            <form onSubmit={uploadForm}>
              <div className="input-group">
              <input type="file" className="form-control" name="caseDocument" onChange={handleFileChange} ref={ref} />

                <button disabled={caseFile === null || uploadFileLoading} className="btn btn-primary" type="submit" id="inputGroupFileAddon04">
                  <div className="row align-items-center px-1">
                    {uploadFileLoading ? (
                      <div className="spinner-border text-light col-3" role="status">
                        <span className="sr-only"></span>
                      </div>
                    ) : null}
                    <p className="col-auto mb-0"> Upload </p>
                  </div>
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
