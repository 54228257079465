import { FC, useContext, useEffect, useState } from "react";
import FooterText from "../../components/footerText";
import AuthSubHeader from "../../components/authSubHeader";
import { APIEndpoints } from "src/types/apiTypes";
import useFetch from "src/hooks/useFetch";
import {  ScheduleRepVisitApiResponse, repVisitUpdateError } from "./types";
import { ToastContext } from "src/context/toastContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastTypes } from "src/types";
import { AuthContext } from "src/context/authContext";
import { useNavigate } from "react-router-dom";


const NewRepVisit: FC = (): JSX.Element => {
  const [radioValue, setRadioValue] = useState("UroGen Support Overview/Demonstration/Support");
  const { showToast } = useContext(ToastContext);
  const { hideLoader } = useContext(LoaderContext);
  const { destroySession } = useContext(AuthContext);
  const navigate = useNavigate();
  const { loading, data: repVisitData, mutate: repVisit, error: repVisitError } = useFetch<ScheduleRepVisitApiResponse>(APIEndpoints.SCHEDULE_REP_VISIT, {}, {}, false)
  const scheduleRepVisit = () => {
      const requestBody = {
        reason: radioValue
      }
      repVisit(requestBody)
  }
  const onClickRadioButton = (value: string) => {
    setRadioValue(value)
  }
  useEffect(() => {
    if (repVisitError) {
      hideLoader();
      const {code, error } = repVisitError as unknown as repVisitUpdateError;
      showToast([error || `Can't Schedule Rep Visit right now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        showToast([`User session has expired`], ToastTypes.ERROR);
        navigate(`/users/sign_in`);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repVisitError]);

  useEffect(() => {
    if (repVisitData || repVisitError) {
      if(repVisitData){
        if(repVisitData.status.code  === 200) {
          showToast([`Rep Visit Scheduled Succesfully`], ToastTypes.SUCCESS);
          navigate('/cases');
        }
      }
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repVisitData, repVisitError]);

  return (
    <div className="container-fluid pt-3" style={{ paddingBottom: `5rem` }}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <AuthSubHeader />

          <div className="card mt-4">
            <div className="card-header">
              <h2 className="text-primary fw-normal"> Schedule a rep visit with your rep </h2>
            </div>

            <div className="card-body">
              <p>Fill out the form below and your rep will get in touch with you shortly</p>

              <div className="card">
                <div className="card-header">
                  <h3 className="text-primary fw-normal"> I would like: </h3>
                </div>

                <div className="card-body">

                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="requestReason" defaultChecked id="supportOverview" onClick={() => onClickRadioButton('UroGen Support Overview/Demonstration/Support')} />
                    <label className="form-check-label" htmlFor="supportOverview">
                      UroGen Support Overview/Demonstration/Support
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="requestReason" id="getJelmyto" onClick={() => onClickRadioButton('How to get JELMYTO')} />
                    <label className="form-check-label" htmlFor="getJelmyto">
                      How to get JELMYTO
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="requestReason" id="jelmytoSupport" onClick={() => onClickRadioButton('JELMYTO access support')} />
                    <label className="form-check-label" htmlFor="jelmytoSupport">
                      JELMYTO access support
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="requestReason" id="reimbursementGuide" onClick={() => onClickRadioButton('Reimbursement Guide questions')} />
                    <label className="form-check-label" htmlFor="reimbursementGuide">
                      Reimbursement Guide questions
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="requestReason" id="other" onClick={() => onClickRadioButton('Other')} />
                    <label className="form-check-label" htmlFor="other">
                      Other
                    </label>
                  </div>
                </div>
              </div>
              <p className="mt-2">
                By providing the information requested above, I authorize UroGen to enter the information I have provided into a may contact me from time to time by telephone, mail, or email to provide information about products or services that may be of interest to me. I understand that the information will be treated confidentially and will be accessed only by authorized personnel or third parties under contract with UroGen or its affiliates.
              </p>
              <button type="button" className="btn btn-primary" disabled={loading} onClick={scheduleRepVisit}>
                Request Visit
              </button>
            </div>
          </div>
          <FooterText footerType="black" />
        </div>
      </div>
    </div>
  );
};

export default NewRepVisit;
