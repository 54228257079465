import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContext } from "src/context/toastContext";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import useFetch from "src/hooks/useFetch";

import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { AddNoteErrorResponse,AddNoteAPIResponseType, CaseNotesProps, CaseNotesType } from "./types";

const CaseNotes: FC<CaseNotesProps> = ({ caseNotes, setNotes, caseObject }): JSX.Element => {
  const [addCaseNote, setAddNoteCase] = useState<string>("");

  const navigate = useNavigate();
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);





  const { data: addNoteResponse, error: addNoteError, mutate: addCaseMutate, removeErrorAndData: clearCaseNoteState } =
    useFetch<AddNoteAPIResponseType>(APIEndpoints.ADD_NOTE);

  useEffect(() => {
    if (addNoteResponse || addNoteError) {
      hideLoader();
      if(addNoteResponse){
        if (addNoteResponse.code === 200) {
          const notes: CaseNotesType = {
            created_at: addNoteResponse.data.created_at,
            id: addNoteResponse.data.id,
            name: addNoteResponse.data.user_fullname,
            description: addNoteResponse.data.description,
          };
          setNotes((prevNotes) => [notes, ...prevNotes])
          showToast([addNoteResponse.message], ToastTypes.SUCCESS);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNoteResponse, addNoteError]);

  const addNoteFunction = (event: any) => {
    setAddNoteCase(event.target.value);
  }

  const onAddNoteClick = () => {
    if (addNoteResponse || addNoteError) clearCaseNoteState();

    setLoader();
    const note = {
      ref_id: caseObject && caseObject.case_information ? caseObject.case_information.case_id : 0,
      ref_type: "Case",
      description: addCaseNote,
    };
    setAddNoteCase("");
    addCaseMutate(note);
  };

  return (
    <div className="card m-2">
      <div className="card-header">
        <h4  className=" text-black m-0">Case Notes</h4>
      </div>

      <div className="card-body">
        <div className={`container-fluid case-notes-listing ${caseNotes.length > 0 ? 'mb-4' : ''}`}>
          {caseNotes.length > 0 ? caseNotes.map((item, index) => (
            <div className={`row mb-2 py-1 align-items-center ${index % 2 === 0 ? 'bg-secondary-subtle' : ''}`} key={index}>
              <div className="col-3">
                <p className="mb-0 fs-6">{item ? item.name || item.user_fullname || "" : ""}</p>
              </div>
              <div className="col-9">
                <p className="mb-0 fs-6">{item ? item.description : ''}</p>
              </div>
              <div className="col-11 text-start pt-1">
                <p className="mb-0 fs-6">{item ? item.created_at : ''}</p>
              </div>
            </div>
          )) : null}
        </div>

        {caseObject && caseObject.case_information && caseObject.case_information.pharmacy === false ? (
          <>
            <div className="input-group flex-nowrap mt-6 h-100">
              <textarea
                onChange={addNoteFunction}
                value={addCaseNote}
                className="form-control"
                placeholder="Type Notes Here"
                rows={4}
                style={{ resize: "none" }}
              />
            </div>

            <button
              onClick={onAddNoteClick}
              disabled={addCaseNote.length === 0 || /^\s*$/.test(addCaseNote)}
              type="button"
              className="btn btn-primary mt-3 w-100"
            >
              Add Note
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CaseNotes;
