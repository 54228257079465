import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import FooterText from "src/components/footerText";
import ContactAndSafetyInfo from "src/components/contactAndSafetyInfo";
import BAAContent from "src/components/baaContent";
import GuestTopHeader from "src/components/guestTopHeader";

import { TextAlignProps } from "src/components/contactAndSafetyInfo/types";


const BAAAgreement: FC = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const practice_name = searchParams.get("practice_name")
  const practice_address = searchParams.get("practice_address")
  const practice_created_at = searchParams.get("created_at");

  return (
    <div className="overflow-auto">
      <GuestTopHeader />
      <div className="container-fluid pt-3 pb-3" style={{ paddingBottom: `5rem` }}>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <ContactAndSafetyInfo alignText={TextAlignProps.TEXT_END} textColor="white" />

            <div className="panel panel-default">
              <div className="panel-body">
                <BAAContent
                  practice_name={practice_name || ""}
                  practice_address={practice_address || ""}
                  practice_created_at={practice_created_at || ""}
                />
              </div>
            </div>

            <FooterText footerType="black" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BAAAgreement;
