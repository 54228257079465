import { FC, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";

import HubInputField from "../../components/inputType";

import { resetpasswordSchema } from "../../validation/resetPassword";
import useFetch from "../../hooks/useFetch";
import { APIEndpoints } from "../../types/apiTypes";
import { ResetPasswordAPIErrorResponseType, ResetPasswordAPISuccessResponseType } from "./types";
import { ToastContext } from "../../context/toastContext";
import { ToastTypes } from "../../types";



const HubResetPassword: FC = (): JSX.Element => {
  const [resetToken, setResetToken] = useState<string>('');
  const [searchParams] = useSearchParams();

  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(resetpasswordSchema),
  });





  const { loading, data, error, removeErrorAndData, mutate } = useFetch<ResetPasswordAPISuccessResponseType>(APIEndpoints.USER_RESET_PASSWORD);

  useEffect(() => {
    const obj = new URLSearchParams(searchParams);
    setResetToken(obj.get('reset_password_token') || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      const { message } = data;
      showToast([message], ToastTypes.SUCCESS);
      navigate("/users/sign_in");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const submitPassword = async (values: { password: string, confirmPassword: string }) => {
    if ((error && error.length > 0) || data) removeErrorAndData();
    mutate({ user: { reset_password_token: resetToken, ...values } });
  };

  return (
    <div className="login-sec height-to-body">
      <div className="container-fluid row justify-content-center align-items-center pt-3 pb-4 height-to-body">
        <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 pb-5 px-4">
          <div className="card">
            <div className="card-header p-3">
              <h4 className="m-0">Reset Your Password</h4>
            </div>

            <div className="card-body">
              <form onSubmit={handleSubmit(submitPassword)} className="reset-formlabel">
                <Controller
                  name="password"
                  control={control}
                  defaultValue=''
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="password"
                      isRowBased={false}
                      isRequired={true}
                      field={field}
                      error={error}
                      inputLabel="New Password"
                      inputType="password"
                    />
                  )}
                />

                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=''
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="confirmPassword"
                      isRowBased={false}
                      isRequired={true}
                      field={field}
                      error={error}
                      inputLabel="Confirm Password"
                      inputType="password"
                    />
                  )}
                />

                <button disabled={loading} className="btn btn-primary" type="submit">
                  <div className="row align-items-center px-1">
                    {loading ? (
                      <div className="spinner-border text-light col-3" role="status">
                        <span className="sr-only"></span>
                      </div>
                    ) : null}
                    <p className="col-auto mb-0"> Reset Password </p>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubResetPassword;
