import { FC, useCallback, useEffect, useState } from "react";
import HighCharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import {
  PIE_CHART_TOP_10PAYOR,
} from "src/constants/highCharts";
import { StatisticsObjectType } from "../caseVolume/types";
import { wrapperFunctionPieChart } from "src/helpers";



const Top10Payors: FC<StatisticsObjectType> = ({ StatisticsObjectType }): JSX.Element => {
  const [topPayor, setTopPayor] = useState({});
  const [caseBySubmission, setCaseBySubmission] = useState({});



  const populateTopPayorPieChart = useCallback(() => {
    const topPayorOptions = {
      ...PIE_CHART_TOP_10PAYOR,
      series: wrapperFunctionPieChart(StatisticsObjectType.top_payors)
    };
    setTopPayor(topPayorOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StatisticsObjectType]);

  useEffect(() => {
    populateTopPayorPieChart();
  }, [populateTopPayorPieChart]);




  const populateCaseBySubmissionPieChart = useCallback(() => {
    const caseBySubmissionOptions = {
      ...PIE_CHART_TOP_10PAYOR,
      series: wrapperFunctionPieChart(StatisticsObjectType.mos)
    };
    setCaseBySubmission(caseBySubmissionOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StatisticsObjectType]);

  useEffect(() => {
    populateCaseBySubmissionPieChart();
  }, [populateCaseBySubmissionPieChart]);



  return (
    <div className="row">
      <div className="col-12 col-md-12 col-lg-6">
        <div className="card mt-3">
          <div className="card-header">
            <h4 className="m-0 text-center">Top 10 Payors</h4>
          </div>
          <div className="card-body">
            <HighchartsReact highcharts={HighCharts} options={topPayor} />
          </div>
        </div>
      </div>

      <div className="col-12 col-md-12 col-lg-6">
        <div className="card mt-3">
          <div className="card-header">
            <h4 className="m-0 text-center">Cases By Submission Mode</h4>
          </div>
          <div className="card-body">
            <HighchartsReact
              highcharts={HighCharts}
              options={caseBySubmission}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top10Payors;
