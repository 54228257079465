import { FC, useCallback, useEffect, useState } from "react"
import HighCharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import { PIE_CHART_TOP_10PAYOR } from "src/constants/highCharts";
import { StatisticsObjectType } from "../caseVolume/types";
import { wrapperFunctionPieChart } from "src/helpers";

const Top10Diagnoses: FC<StatisticsObjectType> = ({StatisticsObjectType}): JSX.Element => {
  const [topPayor, setTopPayor] = useState({});



  const populateTopPayorPieChart = useCallback(() => {
    const topPayorOptions = {
      ...PIE_CHART_TOP_10PAYOR,
      series: wrapperFunctionPieChart(StatisticsObjectType.diagnosis),
    };
    setTopPayor(topPayorOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StatisticsObjectType]);

  useEffect(() => {
    populateTopPayorPieChart();
  }, [populateTopPayorPieChart]);



  return(
    <div className="card mt-3">
      <div className="card-header">
        <h4 className="m-0 text-center">Top 10 Diagnoses</h4>
      </div>
      <div className="card-body">
        <HighchartsReact highcharts={HighCharts} options={topPayor}/>
      </div>
    </div>
  )
}
export  default Top10Diagnoses;
