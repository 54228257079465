
import { FC, useContext, useEffect, useState } from "react"
import { DownloadReportsResponseTypeApi, StatsReportProps } from "./types";
import useFetch from "src/hooks/useFetch";
import { DownloadZipErrorType } from "src/pages/dashboard/types";
import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastContext } from "src/context/toastContext";
import { downloadExcelFile } from "src/helpers/downloadFile";

const StatsReports: FC<StatsReportProps> = ({ getValues }): JSX.Element => {

  const [enabledButton, setEnabledButton] = useState<boolean>(false);
  const { destroySession, userData } = useContext(AuthContext)
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const {
    data: downloadReportsData, error: downloadReportsError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
  } = useFetch<DownloadReportsResponseTypeApi>(APIEndpoints.MANUFACTURE_STATISTICS_REPORT_DOWNLOAD);

  useEffect(() => {
    if (downloadReportsData || downloadReportsError) {
      if (downloadReportsData){
        const { response, report_name } = downloadReportsData;
        downloadExcelFile(response, `${report_name}.xlsx`);
      }
      hideLoader();
      setEnabledButton(false);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportsData, downloadReportsError]);


  const DownloadReport = (reportName: string) => {
    if (getValues) {
      let params = {
        'start_date': getValues().start_date,
        'end_date': getValues().end_date,
        'report_name': reportName,
        ...(userData && userData.ref_type === "rep" ? { user_id: userData.id } : {})
      }
      if (downloadReportsData || downloadReportsError) clearRequestFileData();
      setLoader();
      setEnabledButton(true)
      downloadRequestForm({}, {}, params);
    }



  }
  return (


    <>
      {userData && (userData.ref_type === 'Manufacturer') && (
        <div className="row g-2" >

          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("provider_list_field_view")
            }} disabled={enabledButton}>
              Provider List Field View
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("provider_list_office_view")
            }} disabled={enabledButton}>
              Provider List Office View
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("practice_list_field_view")
            }} disabled={enabledButton}>
              Practice List Field View
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("practice_list_office_view")
            }} disabled={enabledButton}>
              Practice List Office View
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("full_report_office")
            }} disabled={enabledButton}>
              Full Report Office
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("full_report_field")
            }} disabled={enabledButton}>
              Full Report Field
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("med_pa_report_office")
            }} disabled={enabledButton}>
              Med PA Report Office
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("med_pa_report_field")
            }} disabled={enabledButton}>
              Med PA Report Field
            </button>
          </div>
        </div>)}
      {userData && (userData.ref_type === 'rep') && (
        <div className="row g-2">
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("provider_list_field_view")
            }} disabled={enabledButton}>
              Provider List Field View
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("practice_list_field_view")
            }} disabled={enabledButton}>
              Practice List Field View
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("full_report_field")
            }} disabled={enabledButton}>
              Full Report Field
            </button>
          </div>
          <div className="col-6 col-sm-3">
            <button className="btn btn-primary w-100" onClick={() => {
              DownloadReport("med_pa_report_field")
            }} disabled={enabledButton}>
              Med PA Report Field
            </button>
          </div>
        </div>
      )}
    </>

  );
};

export default StatsReports;
