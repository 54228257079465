import { FC, useContext } from "react";

import {RepriseLogo,
 // RepriseReimbursementResourcesLogo
} from "../../assets";
// import { productslogos } from "../../assets";
import { FooterTextProps } from "./types";
import { AuthContext } from "src/context/authContext";


const FooterText: FC<FooterTextProps> = ({ footerType }): JSX.Element => {
  const { isUserAuthorized } = useContext(AuthContext);

  return (
    <div className={`${!isUserAuthorized ? 'bg-white p-3' : 'after-login-trademark pt-2 pb-2'}`}>
      <div className="trademark-body container-fluid">
        <div className="d-flex justify-content-start align-items-center">
          {/*<div className="col-8 col-sm-8 col-md-4">*/}
          {/*  /!* {isUserAuthorized? (*/}
          {/*      <img className="img-fluid" width={350} src={productslogos} alt="Products Logos" />*/}
          {/*  ) : (<img className="img-fluid" width={110} src={RepriseLogo} alt="Reprise Logo"/>)*/}
          {/*  } *!/*/}
          {/*</div>*/}
          {isUserAuthorized && (
            <div className="flex-grow-1">
              <img width={110} className="img-fluid" src={RepriseLogo} alt="Reprise Logo"/>
            </div>
          )
          }
          <small className="flex-grow-1 text-center copyright-text">©2024 Reprise Biomedical. All Rights Reserved.</small>
        </div>
      </div>
    </div>
  );
};

export default FooterText;
