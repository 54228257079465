import { format, parse } from "date-fns";
import { UseFormSetValue } from "react-hook-form";
import { PatientDetailType } from "src/components/caseStepTwo/types";
import { AddCaseProps, InsuranceAttributesProps, PatientAddress, PatientPhoneNumber } from "src/pages/addCase/types";

export const resetPatientDetails = (setValue: UseFormSetValue<AddCaseProps>, globalPatientId: number) => {
  setValue("patient_attributes", {
    global_patient_id: globalPatientId,
    title: "",
    firstname: "",
    middlename: "",
    lastname: "",
    gender: "",
    best_time_to_contact: "",
    dob: "",
    ssn: "",
    address: {} as PatientAddress,
    phone_numbers: [],
    patient_email_id: null,
    patient_email: "",
  });

  setValue("patient_attributes.address", {
    address_name: "home",
    city: "",
    state: "",
    street: "",
    zip: "",
    suite: "",
  })

  setValue("patient_attributes.phone_numbers", [
    { number: "", phone_type: "cell", is_preferred: true, is_ok_to_leave_message: false, id: null } as PatientPhoneNumber
  ])

  setValue("insurances_attributes", [
    {
      insurance_name: "",
      is_primary_insurance: true,
      policy: "",
      relation_to_patient: "self",
      bin: "",
      group_number: "",
      insurance_number: "",
      member_id: "",
      pcn: "",
      relation_firstname: "",
      relation_lastname: "",
      relation_middlename: "",
      rx_group: "",
      global_insurance_id: null,
      insurance_priority: 0,
    } as unknown as InsuranceAttributesProps
  ]);
}

export const addPatientDetails = (setValue: UseFormSetValue<AddCaseProps>, patientDetails: PatientDetailType) => {
  const { data: { patient_info, patient_address, phone_numbers, patient_insurances, patient_email } } = patientDetails;

  setValue("patient_attributes", {
    global_patient_id: patient_info.id,
    title: (patient_info.title ? patient_info.title : ""),
    firstname: patient_info.firstname,
    middlename: patient_info.middlename,
    lastname: patient_info.lastname,
    gender: patient_info.gender,
    best_time_to_contact:
      (patient_info.best_time_to_contact ? patient_info.best_time_to_contact : undefined),
    dob: format(parse(patient_info.dob, "yyyyy-MM-dd", new Date()), "MM/dd/yyyy"),
    ssn: (patient_info.ssn ? patient_info.ssn : undefined),
    address: {} as PatientAddress,
    phone_numbers: [],
    patient_email_id: patient_email.id || null,
    patient_email: patient_email.email || "",
  });

  setValue("patient_attributes.address", {
    street: patient_address.street || "",
    suite: patient_address.suite || "",
    city: patient_address.city || "",
    state: patient_address.state || "",
    zip: patient_address.zip || "",
    address_name: "home",
  });

  if (phone_numbers.length > 0)
    phone_numbers.forEach((phone, index) => {
      setValue(`patient_attributes.phone_numbers.${index}`, {
        phone_type: phone.phone_type,
        number: phone.number.toString(),
        is_preferred: phone.is_preferred,
        is_ok_to_leave_message: phone.is_ok_to_leave_message,
        id: phone.id,
      });
    });
  else
    setValue(`patient_attributes.phone_numbers.0`, {
      phone_type: "cell",
      number: "",
      is_preferred: true,
      is_ok_to_leave_message: false,
      id: null,
    });

  patient_insurances.forEach((insurance, index) => {
    setValue(`insurances_attributes.${index}`, {
      is_primary_insurance: index === 0,
      insurance_name: insurance.insurance_name,
      relation_to_patient: insurance.relation_to_patient,
      relation_firstname: insurance.relation_firstname ? insurance.relation_firstname : undefined,
      relation_middlename: insurance.relation_middlename ? insurance.relation_middlename : undefined,
      relation_lastname: insurance.relation_lastname ? insurance.relation_lastname : undefined,

      policy: insurance.policy,
      group_number: insurance.group_number ? insurance.group_number.toString() : undefined,
      insurance_number: insurance.insurance_number ? insurance.insurance_number : "",

      member_id: insurance.member_id ? insurance.member_id : undefined,
      bin: insurance.bin ? insurance.bin.toString() : undefined,
      pcn: insurance.pcn ? insurance.pcn.toString() : undefined,
      rx_group: insurance.rx_group ? insurance.rx_group : undefined,
      global_insurance_id: insurance.id ? insurance.id.toString() : null,
      insurance_priority: index,
    });
  });
}

export const resetDoctorAndPatientDetails = (setValue: UseFormSetValue<AddCaseProps>) => {
  setValue("doctor_attributes.global_doctor_id", 0);
  resetPatientDetails(setValue, 0);
};
