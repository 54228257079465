import { FC, useCallback, useEffect, useState } from "react"
import HighCharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import { LINE_CHART } from "src/constants/highCharts";
import { StatisticsObjectType } from "./types";
import { format, parse } from "date-fns";
import Select, { GroupBase, OptionsOrGroups, } from "react-select";


const CaseVolume: FC<StatisticsObjectType> = ({ StatisticsObjectType,getStatisticsData }): JSX.Element => {
  const [lineChart, setLineChart] = useState(LINE_CHART);

  const timeData = [
    { value: "day", label: "Daily" },
    { value: "week", label: "Weekly" },
    { value: "month", label: "Monthly" },
    { value: "quarter", label: "Quarterly"},
  ];


  const onTimeChange = (val: string) => {
  getStatisticsData && getStatisticsData(val);
  }


  // Populate line chart options with x-axis and series data
  const populateLineChart = useCallback(() => {
    const lineOptions = { ...lineChart };
    lineOptions.series = wrapperFunctionForLine();
    lineOptions.xAxis = {
      categories: wrapperFunctionForBaseAxis(),
    };
    setLineChart(lineOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StatisticsObjectType]);

  // Create a unified array of unique dates for the x-axis
  const wrapperFunctionForBaseAxis = () => {
    let allDates: any[] = [];
    StatisticsObjectType.case_volume.forEach((caseVolume: any) => {
      allDates = allDates.concat(Object.keys(caseVolume.data));
    });
    const updatedDates = allDates.map((item) => format(item, "MM-dd-yyyy"));
    return Array.from(new Set(updatedDates)).sort(); // Remove duplicates and sort the dates
  };

  // Map data to the line series, filling missing dates with null
  const wrapperFunctionForLine = () => {
    let lineSeriesArray: any = [];
    const xAxisDates = wrapperFunctionForBaseAxis(); // Get the unique x-axis dates

    StatisticsObjectType.case_volume.forEach((caseVolume) => {
      let seriesData: any = [];

      // Map over the xAxisDates, inserting data or null if missing
      xAxisDates.forEach((date) => {
        if (caseVolume.data) {
          const formattedDate = format(parse(date, "MM-dd-yyyy", new Date()), "yyyy-MM-dd");
          // @ts-ignore
          if (caseVolume.data[formattedDate] !== undefined) {
            // @ts-ignore
            seriesData.push(caseVolume.data[formattedDate]);
          } else {
            seriesData.push(0); // Insert null for missing dates
          }
        }
      });

      lineSeriesArray.push({
        type: "line",
        name: caseVolume.name,
        data: seriesData,
        boostThreshold: 0,
      });
    });

    return lineSeriesArray;
  };

  useEffect(() => {
    populateLineChart();
  }, [populateLineChart]);



  return (
    <div className="mt-3">

      <div className="card mt-3">
        <div className="card-header">
          <div className="float-end" style={{width:"100%",  maxWidth:"150px"}}>
            <Select
                isClearable={false}
                options={
                  (timeData && timeData.length > 0
                      ? timeData.map((item) => ({
                        value: item.value,
                        label: item.label,
                      }))
                      : []) as unknown as OptionsOrGroups<
                      string,
                      GroupBase<string>
                  >
                }
                id="time"
                onChange={(val) => onTimeChange((val as unknown as { value: string }).value)}
            />
          </div>
          <h4 className="text-center m-0 mt-2">Case Volume</h4>
        </div>
      </div>
      <div className="card-body">
        <p className="text-center">Line convergence is positive, and higher numbers are better.</p>
        <div className="row">
          <HighchartsReact highcharts={HighCharts} options={lineChart}/>
        </div>
      </div>
    </div>

  )
}
export default CaseVolume;

