import { FC, useEffect, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";

import { GeographicalStatisticsObject, StatisticsApiResponse } from "./types";

import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastTypes } from "src/types";

import FooterText from "src/components/footerText";
import CaseVolume from "src/components/manufacturer/caseVolume";
import GeographicalStatistics from "src/components/manufacturer/geographicalStatistics";
import CaseFilter from "src/components/manufacturer/statistics";
import { StatisticsSearchProps } from "src/components/manufacturer/statistics/types";
import Top10Diagnoses from "src/components/manufacturer/top10Diagnoses";
import Top10Payors from "src/components/manufacturer/top10Payors";
import {AuthContext} from "../../context/authContext";




const Manufacturer: FC = (): JSX.Element => {
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { userData } = useContext(AuthContext);
  const [searchParams, updateSearchParams] = useSearchParams();

  const [queryParams, changeQueryParams] = useState({
    start_date:
      searchParams.get("start_date") ||
      format(new Date().setDate(new Date().getDate() - 30), "yyyy-MM-dd"),
    end_date:
      searchParams.get("end_date") ||
      format(new Date().setDate(new Date().getDate()+1), "yyyy-MM-dd"),
    period: searchParams.get('period') || "",
    // filter: searchParams.get("filter") || "",
    // time: searchParams.get("time") || "",
    type: "index",
    soc_value:searchParams.get("soc_value") || "",
    drug_id:searchParams.get("drug_id") || "",
    user_id: searchParams.get("user_id") || (userData && userData.ref_type === "rep" ? userData.id.toString() : "") || "",
  });

  const { data: statisticsData, mutate: getStatsData } = 
    useFetch<StatisticsApiResponse>(APIEndpoints.MANUFACTURE_STATISTICS, queryParams);

  useEffect(() => {
    if (statisticsData) {
      hideLoader();
      if (statisticsData.code === 200) {
        showToast([statisticsData.message], ToastTypes.SUCCESS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsData]);



  const updateQueryParamsAndCallAPI = (
    updatedQueries: StatisticsSearchProps,
    isReset: boolean = false
  ): void => {
    if (isReset) {
      updateSearchParams({});
      changeQueryParams({
        start_date: format(
          new Date().setDate(new Date().getDate() - 30),
          "yyyy-MM-dd"
        ),
        end_date: format(
          new Date().setDate(new Date().getDate()),
          "yyyy-MM-dd"
        ),
        period: "",
        // filter: "",
        // time: "",
        type: "",
        soc_value:"",
        drug_id:"",
        user_id: "",
      });
    } else {
      changeQueryParams(updatedQueries);
    }

    setLoader();
    getStatsData({}, {}, updatedQueries);
  };

  const getStatisticsData = (period: string) => {
    const updatedQueries = {
      ...queryParams,
      period,
    } 
    changeQueryParams(updatedQueries)
    getStatsData({}, {}, updatedQueries);
  }

  return (
    <div className="container-fluid overflow-auto">
      {statisticsData && (
        <CaseFilter
          defaultQueryParams={queryParams}
          updateQueryParams={updateQueryParamsAndCallAPI}
        />
      )}
      {statisticsData && (
        <CaseVolume StatisticsObjectType={statisticsData.data} getStatisticsData={getStatisticsData} />
      )}
      {statisticsData && (
        <Top10Payors StatisticsObjectType={statisticsData.data} />
      )}
      {statisticsData && (
        <Top10Diagnoses StatisticsObjectType={statisticsData.data} />
      )}
      {/* <KAMsRBDsFAMs
        KAMsListType={statisticsData?.data.kams as unknown as KAMsListType[]}
        FAMsListType={statisticsData?.data.fams as unknown as FAMsListType[]}
        RBDsListType={statisticsData?.data.rbds as unknown as RBDsListType[]}
      /> */}
      {statisticsData?.data && (
        <GeographicalStatistics
          GeographicalStatisticsListType={
            statisticsData?.data
              .geographical_stats as unknown as GeographicalStatisticsObject[]
          }
        />
      )}
      <FooterText footerType="black" />
    </div>
  );
};

export default Manufacturer;
