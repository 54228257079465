import { FC } from "react";
// import { getKeys } from "src/constants/highCharts";
// import FooterText from "src/components/footerText";
// import AuthMaunfacturerSubHeader from "src/components/manufacturer/authManufacturerSubHeader";
// import Statistics from "src/components/manufacturer/statistics";
// import MajorStats from "src/components/manufacturer/majorStats";
// import ScheduledPatients from "src/components/manufacturer/scheduledPatients";
// import UnScheduledPatients from "src/components/manufacturer/unScheduledPatients";
// import CaseDetail from "src/components/manufacturer/caseDetail";
// import OrderDetail from "src/components/manufacturer/orderDetail";
// import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import { TBMsObjectType } from "src/components/manufacturer/TBMsRBDsFRMs/types";

// import { ScheduledPatientPropsType } from "src/components/manufacturer/scheduledPatients/types";
// import TBMs from "src/components/manufacturer/TBMs";
// import {  OrdersObjectProps, Scheduled_PatientsObject, StatisticsApiResponse, statisticsObject, unscheduled_patients } from "../manufacturer/types";
// import { ToastTypes } from "src/types";
// import { AuthContext } from "src/context/authContext";
// import { ToastContext } from "src/context/toastContext";
// import { LoaderContext } from "src/context/loaderContext";
// import { apiConstants } from "src/constants/api";
// import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from "axios";
// import { CasedetailObjectType } from "../manufacturer/types";
// import { StatsSearchProps } from "src/components/manufacturer/statistics/types";
// import { format } from "date-fns";
import AuthMaunfacturerSubHeader from "src/components/manufacturer/authManufacturerSubHeader";

const Territories: FC = (): JSX.Element => {
  // const [design1, setDesign1] = useState(2);
  // let [searchParams, setSearchParams] = useSearchParams();
  // const { destroySession, authToken } = useContext(AuthContext)
  // const { showToast } = useContext(ToastContext);
  // const { hideLoader } = useContext(LoaderContext);
  // const navigate = useNavigate();
  // const location = useLocation();
  // const [statisticsData, setStatisticsData] = useState<StatisticsApiResponse>();
  // // const { item } = location.state;
  // console.log("Item", location); // output: "the-p}
  // const [queryParams, updateQueryParams] = useState<StatsSearchProps>({
  //   start_date: format((new Date()).setMonth((new Date()).getMonth() - 1), "yyyy-MM-dd"),
  //   end_date: format(new Date(), "yyyy-MM-dd"),
  //   practice_id: "",
  //   global_doctor_id: "",
  //   case_id: "",
  //   global_patient_external_id: searchParams.get('global_patient_external_id'),
  // });

  // const handleSubmit=(patientId:string)=>{
  //   // The serialize function here would be responsible for
  //   // creating an object of { key: value } pairs from the
  //   // fields in the form that make up the query.

  //   setSearchParams("global_patient_external_id=" + patientId);
  //   setDesign1(1)
  // }
  // // const updateStats = useCallback(() => {
  // //   getStatsData({}, {}, queryParams);
  // //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // // }, [queryParams]);

  // // useEffect(() => updateStats(), [updateStats]);
  // const getApiData = async (value: string) => {
  //   try {
  //     const options: AxiosRequestConfig = {
  //       method: "GET",
  //       url: apiConstants.baseURL + "/api/v1" + value,
  //       headers: new AxiosHeaders(),
  //     };

  //     if (options.headers instanceof AxiosHeaders && authToken) {
  //       options.headers.setAuthorization(authToken);
  //       options.headers.set("ClientDb", apiConstants.headers.ClientDb)
  //       options.headers.setAccept(apiConstants.headers.Accept)
  //       options.headers.setContentType(apiConstants.headers["Content-Type"])
  //       options.headers.set("Access-Control-Allow-Origin", apiConstants.headers["Access-Control-Allow-Origin"])
  //       //:- axios call has been generated
  //       const response = await axios(options);

  //       const { data } = response;
  //       setStatisticsData(data);
  //     }

  //   } catch (error: unknown) {
  //     hideLoader();
  //     showToast([(error as AxiosError).message || `Can't fetch statistics right now. Please try again in a while.`], ToastTypes.ERROR);
  //     if ((error as AxiosError).message === "Request failed with status code 401") {
  //       destroySession();
  //       navigate(`/users/sign_in`);
  //     }
  //   }

  // }

  // useEffect(() => {
  //   const item = searchParams.get("item");
  //   if (item && authToken) {
  //     // console.log("JSON PARSE", JSON.parse(item));
  //     const data = JSON.parse(item)
  //     // console.log("JSON PARSE Dtataa", data);
  //     getApiData(data.value)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [authToken]);

  // getKeys();
  // const QueryParam  = {
  //   start_date:'2023-04-10',
  //   end_date:'2024-05-11',
  //   practice_id:'',
  //   global_doctor_id:'',
  //   case_id:'',
  //   global_patient_external_id:'',
  // }
  // const { data: statisticsData, error:errorAndData } = useFetch<StatisticsApiResponse>(APIEndpoints.MANUFACTURE_STATISTICS,QueryParam);
  // useEffect(() => {

  //   if (errorAndData && errorAndData.length > 0) {
  //     const {error,code} =  errorAndData  as unknown as statisticsErrorAndData ;
  //       hideLoader();
  //       showToast([error || `Can't fetch statistics right now. Please try again in a while.`], ToastTypes.ERROR);
  //     if (code === 401) {
  //       destroySession();
  //       navigate(`/users/sign_in`);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [errorAndData]);

  // useEffect(() => {
  //   if (statisticsData) {
  //     hideLoader();
  //     if (statisticsData.code === 200) {
  //       const { data } = statisticsData;
  //       //console.log("Statistics Data.....",data)
  //       showToast([statisticsData.message], ToastTypes.SUCCESS);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [statisticsData]);

  return (
    <div className="container-fluid auth-page mb-4 overflow-x-hidden">
      <AuthMaunfacturerSubHeader />
      {/*Map Screen Filter Statistics*/}      
        {/* <Statistics updateQueryParams={updateQueryParams} /> */}
      {/*Only Show Information in Buttons */}
      {/* {statisticsData && <MajorStats
        doses={statisticsData?.data.doses}
        Doctors={statisticsData.data.new_doctors}
        Practice={statisticsData.data.new_practices}
        new_prefs={statisticsData?.data.new_pefs}
        treatments_completed={statisticsData?.data.treatments_completed}
        treatments_scheduled={statisticsData?.data.treatments_scheduled} />
      }
      {statisticsData && <div className="row">
        <div className="col-4">
          <div className="col overflow-auto mt-2">
            <ScheduledPatients
              ScheduledPatientListType={statisticsData.data["Scheduled Patients"] as unknown as Scheduled_PatientsObject[]} />
          </div>
          <div className="col overflow-auto mt-4">
            <UnScheduledPatients UnScheduledPatientListType={statisticsData.data.unscheduled_patients as unknown as unscheduled_patients[]} />

          </div>
          
        </div>
        <div className="col-8 p-0">
          <div className="row">
            <div>
              <CaseDetail CaseDetailListType={statisticsObject.data.case_details as unknown as CasedetailObjectType[]} onPatientIdPress={handleSubmit} />
              <OrderDetail OrderDetailListType={statisticsData.data.orders as unknown as OrdersObjectProps[]} />

            </div>
          </div>
        </div>
        <div className="col-4 overflow-auto">
            <TBMs TBMsListType={statisticsData.data.tbms as unknown as TBMsObjectType[]} />
          </div>
      </div>}
      <FooterText footerType="black" /> */}
    </div>

  );
};

export default Territories;
