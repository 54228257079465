import { FC } from "react";
import { format, parse } from "date-fns";
import DatePicker from "react-datepicker";
import Select, { GroupBase, OptionsOrGroups, PropsValue } from "react-select";

import { QueryStateActions } from "src/pages/dashboard/types";
import { CaseFilterProps } from "./types";
import ReactInputMask from "react-input-mask";



const TableFilters: FC<CaseFilterProps> = ({ state, dropdownData, inputPlaceholder, dispatch, handleSearchWithQueryParam, onClearButtonClicked, onStatusChange }): JSX.Element => {
  const handleDateFromChange = (date: Date | null): void => {
    dispatch({
      type: QueryStateActions.CHANGE_FROM_DATE,
      payload: date ? format(date, "yyyy-MM-dd") : "",
    });
  };

  const handleDateToChange = (date: Date | null): void => {
    dispatch({
      type: QueryStateActions.CHANGE_TO_DATE,
      payload: date ? format(date, "yyyy-MM-dd") : "",
    });
  };

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: QueryStateActions.CHANGE_SEARCH_QUERY,
      payload: e.target.value
    });
  };

  const onEnterPress  = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchWithQueryParam()
    }
  }

  return (
    <div className="row g-2">
      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
        <div className="row g-2">
          <div className="col-12 col-sm-12 col-md-4">
            <Select
              options={dropdownData as unknown as OptionsOrGroups<string | boolean, GroupBase<string | boolean>>}
              noOptionsMessage={() => ""}
              onChange={(val) => {
                const selectedValue = val as unknown as { label: string; value: string | boolean };
                (typeof selectedValue.value === "boolean") ?
                  dispatch({
                    type: QueryStateActions.CHANGE_CASE_STATUS,
                    payload: selectedValue.value,
                  }) :
                  dispatch({
                    type: QueryStateActions.CHANGE_CATEGORY_TYPE,
                    payload: selectedValue.value,
                  });
                onStatusChange(selectedValue.label);
              }}
              isSearchable={false}
              value={((openValue: string | boolean) => {
                const data = dropdownData.find(singleStatus =>
                  singleStatus.value === openValue
                );
                if (data) return data as unknown as PropsValue<string | boolean>;
              })(typeof state.open === "boolean" ? state.open : state.category_type)}
            />
          </div>
          <div className="col-6 col-sm-6 col-md-4">
            <DatePicker
              // value={state.date_from && format(parse(state.date_from, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")}
              selected={state.date_from.length > 0 ? parse(state.date_from, "yyyy-MM-dd", new Date()) : null}
              onChange={handleDateFromChange}
              dateFormat="MM/dd/yyyy"
              icon="bi bi-calendar"
              className="form-control rounded-1" wrapperClassName="w-100"
              placeholderText="mm/dd/yyyy"
              customInput={<ReactInputMask mask="99/99/9999">{(inputProps: {}) => <input {...inputProps} />}</ReactInputMask>}
              maxDate={state.date_to ? new Date(state.date_to) : undefined}
            />
          </div>
          <div className="col-6 col-sm-6 col-md-4">
            <DatePicker
              // value={state.date_to && format(parse(state.date_to, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")}
              selected={state.date_to.length > 0 ? parse(state.date_to, "yyyy-MM-dd", new Date()) : null}
              onChange={handleDateToChange}
              dateFormat="MM/dd/yyyy"
              className="form-control rounded-1" wrapperClassName="w-100"
              placeholderText="mm/dd/yyyy"
              customInput={<ReactInputMask mask="99/99/9999">{(inputProps: {}) => <input {...inputProps} />}</ReactInputMask>}
              minDate={state.date_from ? new Date(state.date_from) : undefined}
            />
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
        <div className="row g-2">
          <div className="col-12 col-sm-12 col-md-7 col-lg-8">
            <input type="text" onChange={handleSearchQueryChange} value={state.search_query}
                   placeholder={inputPlaceholder} className="form-control rounded-1" onKeyDown={onEnterPress}/>
          </div>

          <div className="col-12 col-sm-12 col-md-5 col-lg-4">
            <div className="row g-2">
              <div className="col-6">
                <button type="button" onClick={onClearButtonClicked} className="btn btn-outline-secondary w-100">
                  Clear
                </button>
              </div>
              <div className="col-6">
                <button type="button" onClick={() => {
                  dispatch({type: QueryStateActions.CHANGE_PAGE, payload: 1,});
                  handleSearchWithQueryParam({...state, page: 1});
                }} className="btn btn-primary w-100">
                  <i className="bi bi-search me-2"></i> Find
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilters;
