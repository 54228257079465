//:- Validation based constants
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,70}$/;

export const US_STATES = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Colombia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "FM", label: "Federated States of Micronesia" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota"},
  { value: "MS", label: "Mississippi"},
  { value: "MO", label: "Missouri"},
  { value: "MP", label: "Northern Mariana Islands"},
  { value: "MT", label: "Montana"},
  { value: "NE", label: "Nebraska"},
  { value: "NV", label: "Nevada"},
  { value: "NH", label: "New Hampshire"},
  { value: "NJ", label: "New Jersey"},
  { value: "NM", label: "New Mexico"},
  { value: "NY", label: "New York"},
  { value: "NC", label: "North Carolina"},
  { value: "ND", label: "North Dakota"},
  { value: "OH", label: "Ohio"},
  { value: "OK", label: "Oklahoma"},
  { value: "OR", label: "Oregon"},
  { value: "PA", label: "Pennsylvania"},
  { value: "PW", label: "Palau"},
  { value: "PR", label: "Puerto Rico"},
  { value: "RI", label: "Rhode Island"},
  { value: "SC", label: "South Carolina"},
  { value: "SD", label: "South Dakota"},
  { value: "TN", label: "Tennessee"},
  { value: "TX", label: "Texas"},
  { value: "UT", label: "Utah"},
  { value: "VT", label: "Vermont"},
  { value: "VI", label: "Virgin Islands"},
  { value: "VA", label: "Virginia"},
  { value: "WA", label: "Washington"},
  { value: "WV", label: "West Virginia"},
  { value: "WI", label: "Wisconsin"},
  { value: "WY", label: "Wyoming"},
];

export const ACCEPTED_FILE_EXTENSIONS: { [key: string]: string; } = {
  '.pdf': 'application/pdf',
  '.doc': 'application/msword',
  '.tiff': 'image/tiff',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.ccd': 'application/x-ccd',
  '.cda': 'application/x-cdf',
  '.ccr': 'application/x-ccr',
  '.ccda': 'application/x-ccda',
};

export const ACCEPTED_FILE_EXTENSIONS_CASE: { [key: string]: string; } = {
  '.pdf': 'application/pdf',
  '.doc': 'application/msword',
  '.tiff': 'image/tiff',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.ccd': 'application/x-ccd',
  '.cda': 'application/x-cdf',
  '.ccr': 'application/x-ccr',
  '.ccda': 'application/x-ccda',
};

export const ACCEPTED_PRECERT_FILE_EXTENSIONS: { [key: string]: string; } = {
  ...ACCEPTED_FILE_EXTENSIONS,
  '.txt': 'text/plain',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png'
};


export const USER_TYPES = {
  HCP: ['GlobalDoctor', 'Practice', 'Contact', 'Organization', 'CentralManagerUsers'],
  MANUFACTURER: ['Manufacturer', 'regional_manager', 'district_manager', 'rep', 'area_manager'],
  PHARMACY: ['Pharmacy', 'PharmacyOrg']
};
export const STATIC_CPT_CODES = [
  {
    value: '15271',
    label: '15271',
  },
  {
    value: '15273',
    label: '15273',
  },
  {
    value: '15275',
    label: '15275',
  },
  {
    value: '15277',
    label: '15277',
  },
];