import { FC } from "react";

const DropdownStatusColorMeaning: FC = (): JSX.Element => {
  return (
    <li className="nav-item dropdown">
      <button className="btn btn-link nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
        Status Color Meaning
      </button>

      <ul className="colors-dropdown dropdown-menu dropdown-menu-end p-2">
        <p className="fw-lighter fs-6 p-2 lh-1" aria-expanded="false">
          On the case screen, you can see the status of your case in real time. The status is also color-coded to make it easier to see what is happening at a glance:
        </p>

        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <div className="row">
            <div className="col-sm-3">
              <span className="btn background-color-1 my-1 text-white" style={{ fontSize: '0.8rem' }}>
                Lt Blue
              </span>
            </div>
            <div className="col-sm-9 lh-1 p-2" style={{ fontSize: '0.8rem' }}>
              The initial color for a case, indicating it is pending
              investigation and no action is currently required.
            </div>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <div className="row">
            <div className="col-sm-3 ml-3 pl-3">
              <span className="btn background-color-5 my-1 text-white" style={{ fontSize: '0.8rem' }}>
                Yellow
              </span>
            </div>
            <div className="col-sm-9 lh-1 p-2" style={{ fontSize: '0.8rem' }}>
              Some action or documentation is required by you, such as
              providing clinical documentation for precertification.
            </div>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <div className="row">
            <div className="col-sm-3">
              <span className="btn background-color-2 my-1 text-white" style={{ fontSize: '0.8rem' }}>
                Blue
              </span>
            </div>
            <div className="col-sm-9 lh-1 p-2" style={{ fontSize: '0.8rem' }}>
              Benefits investigation is complete and no further actions
              are required.
            </div>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider" />
        </li>

        {/* <li>
          <div className="row">
            <div className="col-sm-3">
              <span className="btn background-color-3 my-1 text-white" style={{ fontSize: '0.8rem' }}>
                Green
              </span>
            </div>
            <div className="col-sm-9 lh-1 p-2" style={{ fontSize: '0.8rem' }}>
              A treatment decision has been indicated.
            </div>
          </div>
        </li> */}

        {/* <li>
          <hr className="dropdown-divider" />
        </li> */}

        <li>
          <div className="row">
            <div className="col-sm-3">
              <span className="btn background-color-4 my-1 text-white" style={{ fontSize: '0.8rem' }}>
                Red
              </span>
            </div>
            <div className="col-sm-9 lh-1 p-2" style={{ fontSize: '0.8rem' }}>
              The request has been cancelled. Please review the case
              notes for further details.
            </div>
          </div>
        </li>
      </ul>
    </li>
  );
};

export default DropdownStatusColorMeaning;
