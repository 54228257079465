import { FC } from "react";
import { ESCALATION_EMAIL, FAX_NUMBER, PHONE_NUMBER } from "src/constants/contact";

const GuestTopHeader: FC = (): JSX.Element => {
  return (
      <div className="p-2 topHead-info">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 col-12 d-none d-lg-block d-md-block">
              <p className="m-0 text-start">For US Healthcare Professionals</p>
            </div>
            <div className="col-md-8 col-12 d-flex flex-wrap align-items-center justify-content-center justify-content-md-end">
              <span><i className="bi bi-telephone"></i> {PHONE_NUMBER}</span>
              <span className="px-3"><i className="bi bi-printer"></i> {FAX_NUMBER}</span>
              <span>
                <i className="bi bi-globe2 pe-1"></i>
                <a className="text-white text-decoration-none" href="https://reprisebio.com/" target="_blank" rel="noopener noreferrer">{ESCALATION_EMAIL}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default GuestTopHeader;
