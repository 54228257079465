import { FC, useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import EnrollmentCard from "src/components/enrollmentCard";
import HubInputField from "src/components/inputType";

import { ToastContext } from "src/context/toastContext";
import useFetch from "src/hooks/useFetch";

import { NPILookupResponse, ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { EnrollmentPageUserForm } from "./types";
import { LoaderContext } from "src/context/loaderContext";
import { populatePrescriberEnrollmentForm, resetPrescriberFormFields } from "src/helpers/enrollment";
import ReactInputMask from "react-input-mask";

const EnrollmentPracticeForm: FC<EnrollmentPageUserForm> = ({ parentNpi, control, formState, resetField, getValues, setValue, trigger, prescriberIndex, specialityData, removePrescriber }): JSX.Element => {
  const [preservedNpi, updatePreservedNpi] = useState<string>(parentNpi);
  const [allSpecialites, setSpecialites] = useState<OptionsOrGroups<string, GroupBase<string>>>();

  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);

  /**
   * @ConvertingSpecialities THis will support select dropdown with search functionaltiy
   */
  useEffect(() => {
    const specialityList: { value: string; label: string; }[] = [];
    specialityData?.forEach((speciality) => {
      specialityList.push({
        value: speciality.code,
        label: speciality.name,
      });
    });

    setSpecialites(specialityList as unknown as OptionsOrGroups<string, GroupBase<string>>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialityData]);



  /**
   * @NPILookup calling npi lookup for details
   * @first useFetch hook for api call
   * @second useFetch error handler
   * @third useFetch success handler
   * @fourth npi lookup api trigger
   */
  const { data: npiLookupData, error: npiLookupError, mutate: lookupNpi, removeErrorAndData: clearlookupNpi } = useFetch<NPILookupResponse>(APIEndpoints.NPI_LOOKUP);

  /** @third */
  useEffect(() => {
   if(npiLookupData || npiLookupError){
     if (npiLookupData) {
       if (npiLookupData.doctor_data !== null) {
         hideLoader();
         showToast([`Doctor Data Found`], ToastTypes.INFO);
         populatePrescriberEnrollmentForm(setValue, npiLookupData, preservedNpi, prescriberIndex);
         trigger([`enrollment_doctors_attributes.${prescriberIndex}`]);
       }

       if (npiLookupData.doctor_data === null) {
         showToast([`No Data Found`], ToastTypes.ERROR);
         resetPrescriberFormFields(resetField, prescriberIndex);
         hideLoader();
       }
     }
     if (npiLookupError) {
       hideLoader();
     }
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npiLookupData, npiLookupError]);

  /** @fourth */
  const callNPILookup = () => {
    const npi = getValues(`enrollment_doctors_attributes.${prescriberIndex}.npi`);

    if (!npiLookupData || npi !== preservedNpi) {
      const npiError = formState.errors && formState.errors.enrollment_doctors_attributes && formState.errors.enrollment_doctors_attributes[prescriberIndex]?.npi;
      if (
        !npi || 
        (npi && npi.length === 0) || 
        npiError
      )
        showToast([`Please enter valid NPI for lookup`], ToastTypes.ERROR);
      else {
        setLoader();
        updatePreservedNpi(npi);
        clearlookupNpi();
        const data = {
          'global_doctor[npi]': npi,
        };
        lookupNpi(null, null, data);
      }
    }
  };

  return (
    <EnrollmentCard isTextCenter={false} isCrossBtnVisible={prescriberIndex !== 0} clickHandlerForCrossBtn={removePrescriber} cardHeader="Provider">
      <>
        <div className="row my-2">
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.npi`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_doctors_attributes.${prescriberIndex}.npi`}
                  inputLabel="NPI"
                  inputPlaceholder="NPI"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                  onBlur={callNPILookup}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      callNPILookup();
                    }
                  }}
                />
              )}
            />
          </div>
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.tax_id`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_doctors_attributes.${prescriberIndex}.tax_id`}
                  inputLabel="Tax ID"
                  isRequired={false}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.firstname`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_doctors_attributes.${prescriberIndex}.firstname`}
                  inputLabel="First Name"
                  inputPlaceholder="First Name"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.lastname`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_doctors_attributes.${prescriberIndex}.lastname`}
                  inputLabel="Last Name"
                  inputPlaceholder="Last Name"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.middlename`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_doctors_attributes.${prescriberIndex}.middlename`}
                  inputLabel="Middle Name"
                  isRequired={false}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.ref_taxonomy_id`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputLabel="Ref taxonomy"
                  inputId={`enrollment_doctors_attributes.${prescriberIndex}.ref_taxonomy_id`}
                  inputPlaceholder="Select a Taxonomy..."
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={true}
                  dropdownData={allSpecialites || []}
                />
              )}
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.phone_number`}
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <div className="row mb-3">
                  <label htmlFor={`enrollment_doctors_attributes.${prescriberIndex}.phone_number`} className="form-label col-lg-4 col-md-4 col-12">
                    Phone Number *
                  </label>
                  <div className="col-lg-8 col-md-8 col-12">
                    <ReactInputMask
                      {...field}
                      mask="(999) 999-9999"
                      onChange={(e) => field.onChange(e.target.value.replace(/\D/g, ''))}
                    >
                      {(inputProps: {}) => <input type="text" id={`enrollment_doctors_attributes.${prescriberIndex}.phone_number`} className="form-control" placeholder="(555) 555-5555" {...inputProps} />}
                    </ReactInputMask>

                    {error && error.message ? (
                      <div dangerouslySetInnerHTML={{__html: error.message}} className="text-danger text-small mt-2"></div>
                    ) : null}
                  </div>
                </div>
              )}
            />
          </div>
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.fax_number`}
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <div className="row mb-3">
                  <label htmlFor={`enrollment_doctors_attributes.${prescriberIndex}.fax_number`} className="form-label col-lg-4 col-md-4 col-12">
                    Fax Number *
                  </label>
                  <div className="col-lg-8 col-md-8 col-12">
                    <ReactInputMask
                      {...field}
                      mask="(999) 999-9999"
                      onChange={(e) => field.onChange(e.target.value.replace(/\D/g, ''))}
                    >
                      {(inputProps: {}) => <input type="text" id={`enrollment_doctors_attributes.${prescriberIndex}.fax_number`} className="form-control" placeholder="(555) 555-5555" {...inputProps} />}
                    </ReactInputMask>

                    {error && error.message ? (
                      <div dangerouslySetInnerHTML={{__html: error.message}} className="text-danger text-small mt-2"></div>
                    ) : null}
                  </div>
                </div>
              )}
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.sln`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_doctors_attributes.${prescriberIndex}.sln`}
                  inputLabel="SLN"
                  inputPlaceholder="State License Number"
                  field={field}
                  isRequired={false}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.email`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_doctors_attributes.${prescriberIndex}.email`}
                  inputLabel="Email"
                  field={field}
                  isRequired={false}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  error={error}
                />
              )}
            />
          </div>
        </div>

        {/* <div className="row my-2"> */}
          {/* <div className="col-lg-6">
            <Controller
              name={`enrollment_doctors_attributes.${prescriberIndex}.ptan`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_doctors_attributes.${prescriberIndex}.ptan`}
                  inputLabel="PTAN"
                  inputPlaceholder="Provider Transaction Access Number"
                  field={field}
                  isRequired={false}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  error={error}
                />
              )}
            />
          </div> */}
          
        {/* </div> */}
      </>
    </EnrollmentCard>
  );
};

export default EnrollmentPracticeForm;
