import { FC, useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import WithHeaderandFooter from "../hoc";
import HubSplashScreen from "../pages/splashscreen";

import { PublicRoutes, AuthenticatedRoutes, ManufacturerRoutes } from "./AppRoutes";
import { FooterContext } from "src/context/footerContext";
import { AuthContext } from "src/context/authContext";



const HubRoutes: FC = (): JSX.Element => {
  const { isUserAuthorized, isManufacturer } = useContext(AuthContext);
  const { isAccordianShown, changeAccordianVisibility } = useContext(FooterContext);
  const location = useLocation();

  useEffect(() => {
    if (isAccordianShown)
      changeAccordianVisibility();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const manufacturerRoutes = ManufacturerRoutes.map((route) => (
    <Route
      key={route.route}
      path={route.route}
      index={route.isIndex}
      element= {
        route.removeHeaderandFooter === false ?
          <WithHeaderandFooter WrappedComponent={route.component} /> : <route.component/>
      }
    />
  ));

  const authentictedRoutes = 
    AuthenticatedRoutes.map((route) => (
      <Route
        key={route.route}
        path={route.route}
        index={route.isIndex}
        element= {
          route.removeHeaderandFooter === false ?
            <WithHeaderandFooter WrappedComponent={route.component} /> : <route.component/>
        }
      />
    ));

  const publicRoutes = PublicRoutes.map((route) => (
    <Route
      key={route.route}
      path={route.route}
      index={route.isIndex}
      element= {
        route.removeHeaderandFooter === false ?
          <WithHeaderandFooter WrappedComponent={route.component} /> : <route.component/>
      }
    />
  ));

  return (
    <Routes>
      {isUserAuthorized ? (
        isManufacturer ? (
          manufacturerRoutes
        ) : (
          authentictedRoutes
        )
      ) : (
        publicRoutes
      )}

      <Route path="/" element={<HubSplashScreen />} />
      <Route path="*" element={<HubSplashScreen />} />
    </Routes>
  );
};

export default HubRoutes;
