import { FC, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import FooterText from "../../components/footerText";
import HubInputField from "../../components/inputType";

import useFetch from "../../hooks/useFetch";
import { ToastContext } from "../../context/toastContext";
import { AuthContext } from "../../context/authContext";

import { createUserAPIResponseType } from "./types";
import { APIEndpoints } from "../../types/apiTypes";
import { ToastTypes } from "../../types";
import { UpdateUserSchema } from "src/validation/updateUser";



const HubUpdateUser: FC = (): JSX.Element => {
  const { userData, updateUserData, destroySession } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const { control, setValue, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(UpdateUserSchema),
  });

  useEffect(() => {
    if (userData) {
      setValue("firstname", userData.firstname);
      setValue("middlename", userData.middlename || "");
      setValue("lastname", userData.lastname);
      setValue("email", userData.email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);





  const { loading: userLoading, data: updateUserApiResponse, mutate: addNewUser, error, removeErrorAndData } =
    useFetch<createUserAPIResponseType>(APIEndpoints.USER_UPDATE, {}, { userId: userData?.id });

  useEffect(() => {
    if (updateUserApiResponse) {
      const { code, message, data } = updateUserApiResponse.status;

      if (code === 200) {
        if (data) {
          const { user } = data;

          if (user) {
            showToast([message], ToastTypes.SUCCESS);

            //:- Runs if user has setup a new password and needs to login again

              updateUserData(user);

          }
        }
      } else if (code === 500) {
        showToast([message], ToastTypes.ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserApiResponse]);

  const submitForm = async (values: unknown) => {
    if ((error && error.length > 0) || updateUserApiResponse) removeErrorAndData();
    addNewUser({ user: values });
  };





  return (
    <div className="container-fluid d-flex flex-fill auth-page bg-light overflow-auto">
      <div className="container d-flex flex-column">
        <div className="flex-fill">
          <div className="card mt-4">
            <div className="card-header">
              <h4 className="mb-0">
                Edit Account
              </h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(submitForm)}>

                <p className="fs-6">
                  You may edit your user account information here. Note that by using this platform, you accept and
                  agree
                  to the terms of the Business Associates Agreement below.
                </p>

                <div className="mb-3">
                  <Controller
                      name="firstname"
                      control={control}
                      defaultValue=""
                      render={({field, fieldState: {error}}) => (
                          <HubInputField
                              inputId="firstname"
                              isRowBased={true}
                              isRequired={false}
                              field={field}
                              error={error}
                              inputLabel="Firstname"
                              inputType="firstName"
                          />
                      )}
                  />
                </div>

                <div className="mb-3">
                  <Controller
                      name="middlename"
                      control={control}
                      defaultValue=""
                      render={({field, fieldState: {error}}) => (
                          <HubInputField
                              inputId="middlename"
                              isRowBased={true}
                              isRequired={false}
                              field={field}
                              error={error}
                              inputLabel="Middlename"
                              inputType="middleName"
                          />
                      )}
                  />
                </div>

                <div className="mb-3">
                  <Controller
                      name="lastname"
                      control={control}
                      defaultValue=""
                      render={({field, fieldState: {error}}) => (
                          <HubInputField
                              inputId="lastname"
                              isRowBased={true}
                              isRequired={false}
                              field={field}
                              error={error}
                              inputLabel="Lastname"
                              inputType="lastName"
                          />
                      )}
                  />
                </div>

                <div className="mb-3">
                  <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({field, fieldState: {error}}) => (
                          <HubInputField
                              inputId="email"
                              isRowBased={true}
                              isRequired={true}
                              field={field}
                              error={error}
                              inputLabel="Email"
                              inputType="email"
                          />
                      )}
                  />
                </div>

                <div className="card p-4 mb-2 bg-light-subtle border-light-subtle">
                  <h5 className="text-primary mb-0">Password</h5>
                  <p>Password must be at least 10 characters long (no spaces) and include at least one uppercase
                    letter, at least three lowercase letters, one special character, and a number.</p>
                  <div className="mb-3">
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="password"
                                isRowBased={true}
                                isRequired={false}
                                field={field}
                                error={error}
                                inputLabel="Password"
                                inputType="password"
                            />
                        )}
                    />
                  </div>
                  <div className="">
                    <Controller
                        name="password_confirmation"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="password_confirmation"
                                isRowBased={true}
                                isRequired={false}
                                field={field}
                                error={error}
                                inputLabel="Confirm Password"
                                inputType="password"
                            />
                        )}
                    />
                  </div>
                </div>

                {/* <div className="card mt-3">
                  <div className="card-header">
                    <h4 className="text-primary p-2 mb-0">By Logging in, You Agree to the Terms of the Below Business Associates Agreement.</h4>
                  </div>

                  <div className="card-body overflow-auto" style={{ maxHeight: "400px" }}>
                    <BAAContent />
                  </div>
                </div> */}

                <button disabled={userLoading} className="btn btn-primary d-flex" type="submit">
                  {userLoading ? (
                      <i className="spinner-border text-white me-2" style={{width: `24px`, height: `24px`}}></i>
                  ) : null}
                  <p className="col-auto mb-0">Save</p>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="bg-white mt-3"><FooterText footerType="black"/></div>
      </div>
    </div>
  );
};

export default HubUpdateUser;


