import { FC } from "react";
import { Controller } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import HubInputField from "../../inputType";

import { US_STATES } from "src/constants";
import { PATIENT_ADDR_TYPES } from "src/constants/cases";
import { AddPatientAddressProps } from "./types";

const AddCasePatientAddress: FC<AddPatientAddressProps> = ({ control }): JSX.Element => {
  return (
    <div className="card m-1 mx-0 mb-3">
      <div className="card-header">
        <h4 className="m-0">Patient Address</h4>
      </div>
      <div className="card-body">
        <div className="row">
          <label className="form-label col-lg-2 col-md-2 col-12">Address *</label>
          <div className="col-lg-8 col-md-6">
            <Controller
              name="patient_attributes.address.street" control={control} defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField isRowBased={true} isRequired={true} field={field} inputPlaceholder="Street *"
                  inputId="Address" error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-2 col-md-4">
            <Controller name="patient_attributes.address.suite" control={control} defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField isRowBased={true} isRequired={false} field={field}
                  inputPlaceholder="Suite" inputId="Suite" error={error}
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <label className="form-label col-lg-2 col-md-2 col-12">City State, Zip *</label>
          <div className="col-lg-4 col-md-6">
            <Controller
              name="patient_attributes.address.city"
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  isRowBased={true}
                  isRowInsideAnother={true}
                  isRequired={true}
                  field={field}
                  inputPlaceholder="City *"
                  inputId="patient_attributes.address.city"
                  error={error}
                />
              )}
            />
          </div>

          <div className="col-lg-3 col-md-6">
            <Controller
              name="patient_attributes.address.state"
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  inputId="patient_attributes.address.state"
                  isRequired={true}
                  inputPlaceholder="Select a State* ..."
                  isRowBased={true}
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={false}
                  dropdownData={
                    US_STATES.map(
                      (item) => ({value: item.value, label: item.value})
                    ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                  }
                />
              )}
            />
          </div>

          <div className="col-lg-3 col-md-6">
            <Controller
              name="patient_attributes.address.zip"
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  inputId="patient_attributes.address.zip"
                  inputPlaceholder="Zip *"
                  isRowBased={true}
                  isRequired={false}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
        </div>

        <Controller
          name="patient_attributes.address.address_name"
          control={control}
          defaultValue="home"
          render={({ field, fieldState: { error } }) => (
            <HubInputField
              inputId="patient_attributes.address.address_name"
              isRequired={true}
              isRowBased={true}
              inputLabel="Type"
              field={field}
              error={error}
              isDropdown={true}
              isSearchable={false}
              inputPlaceholder="Select address type ..."
              dropdownData={PATIENT_ADDR_TYPES as unknown as OptionsOrGroups<string, GroupBase<string>>}
            />
          )}
        />
      </div>
    </div>
  );
};

export default AddCasePatientAddress;
