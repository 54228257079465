import {
  GeographicalStatisticsObject,
  topPayorsMOSECRProps,
} from "src/pages/manufacturer/types";
import packageInfo from "../../package.json";
import axios from "axios";

export const objectToQueryString = (obj: any) => {
  const keys = Object.keys(obj);
  const keyValuePairs = keys.map((key) => {
    return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
  });
  return keyValuePairs.join("&");
  // more code goes here
};
// export const wrapperFunction = (value: GeographicalStatisticsObject[]) => {
//   const dataArray: any = [];
//   if (value && value.length > 0) {
//     value.forEach((item) => {
//       const array: [string, number] = [
//         "us-" + item.state.toLocaleLowerCase(),
//         item.case_count,
//       ];
//       dataArray.push(array);
//     });
//   }
//   console.log("DataArray.....",dataArray)
//   return dataArray;
// };


// export const wrapperFunction = (value: GeographicalStatisticsObject[]) => {
//   const dataArray: Array<[string, number]> = [];

// /////////////       These Commented Code must be used for  future reference ////////////////
//   // Define a map for U.S. territories, if necessary
//   // const territoryCodes: { [key: string]: string } = {
//   //   "Puerto Rico": "us-pr",
//   //   Guam: "us-gu",
//   //   "American Samoa": "us-as",
//   //   "Northern Mariana Islands": "us-mp",
//   //   "U.S. Virgin Islands": "us-vi",
//   // };
//   //   ["pr-3614", 67], // puerto rico
//   //   ["gu-3605", 61], // Guam
//   //   ["mp-ti", 62], // tinian
//   //   ["mp-sa", 63], // saipan
//   //   ["mp-ro", 64] // rota
//   //   ['as-6515', 65],// eastern
//   //  ['as-6514', 66],// eastern
//   //  ['vi-3617', 68],// Saint Thomas
//   //  ['vi-6398', 69],// Saint Thomas
//   //  ['vi-6399', 70] // Saint Croix

// /////////////       The Above Commented Code must be used for  future reference ////////////////



//   if (value && value.length > 0) {
//     value.forEach((item) => {
//       // Check if it's a state or territory
//       const stateCode = item.state.toLocaleLowerCase();
//       const prefixedCode = "us-" + stateCode;
//       if (prefixedCode === "us-pr") {
//         dataArray.push(['pr-3614', item.case_count])
//       } else if (prefixedCode === "us-gu") {
//         dataArray.push(['gu-3605', item.case_count]);
//       } else if (prefixedCode === "us-as") {
//         dataArray.push(["as-6515", item.case_count]);
//         dataArray.push(["as-6514", item.case_count]);
//       } else if (prefixedCode === "us-mp") {
//         dataArray.push(["mp-ti", item.case_count]);
//         dataArray.push(["mp-sa", item.case_count]);
//         dataArray.push(["mp-ro", item.case_count]);
//       }else if (prefixedCode === "us-vi") {
//         dataArray.push(['vi-3617', item.case_count]);
//         dataArray.push(['vi-6398', item.case_count]);
//         dataArray.push(['vi-6399', item.case_count]);
//       }else {
//         dataArray.push([prefixedCode, item.case_count]);
//       }
//     });
//   }
//   return dataArray;
// };


export const wrapperFunction = (value: GeographicalStatisticsObject[]) => {
  const dataArray: Array<[string, number]> = [];

  // Map of specific codes for U.S. territories
  const territoryMap: { [key: string]: string[] } = {
    "us-pr": ['pr-3614'],
    "us-gu": ['gu-3605'],
    "us-as": ['as-6515', 'as-6514'],
    "us-mp": ['mp-ti', 'mp-sa', 'mp-ro'],
    "us-vi": ['vi-3617', 'vi-6398', 'vi-6399']
  };

  if (value && value.length > 0) {
    value.forEach((item) => {
      const stateCode = item.state.toLocaleLowerCase();
      const prefixedCode = "us-" + stateCode;

      // Check if the state is in the territoryMap
      if (territoryMap[prefixedCode]) {
        // Add all associated codes for the territory
        territoryMap[prefixedCode].forEach(code => {
          dataArray.push([code, item.case_count]);
        });
      } else {
        // For regular states
        dataArray.push([prefixedCode, item.case_count]);
      }
    });
  }

  return dataArray;
};

export const wrapperFunctionPieChart = (
  pieChartData: topPayorsMOSECRProps[]
) => {
  return [
    {
      name: "Percentage",
      type: "pie",
      data: pieChartData.map((item) => ({
        name: `${(item.name || "")
          .toLowerCase()
          .replace(/(^\w|[-\s]\w)/g, (match) => match.toUpperCase())} <b>(${
          item.value
        })</b>`,
        y: item.percentage,
      })),
    },
  ];
};

export const updateApiVersion = async () => {
  const response = await axios(
    `${process.env.REACT_APP_BASE_URL}/update_api_version?APP_VERSION=${packageInfo.version}`,
    {
      method: "GET",
      headers: {
        ClientDb: process.env.REACT_APP_CLIENT_DB,
      },
    }
  );
  console.log(response, "     ");
};

export const emptyCacheAndCookies = () => {
  document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // Makes sure the page reloads. Changes are only visible after you refresh.
    setTimeout(() => window.location.reload(), 2000);
  }
};
