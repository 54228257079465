import { FC } from "react";
import { EnrollmentCardProps } from "./types";

const EnrollmentCard: FC<EnrollmentCardProps> = ({ cardHeader, isTextCenter, index, isCrossBtnVisible, clickHandlerForCrossBtn, children }): JSX.Element => {
  return (
    <div className="card mb-4">
      <div className="card-header">
        <div className="row">
          <div className={`col-${isCrossBtnVisible ? 6 : 12}`}>
            <h3 className={`mb-0 ${isTextCenter ? 'text-center' : 'text-start'}`}>{cardHeader}</h3>
          </div>

          {isCrossBtnVisible && clickHandlerForCrossBtn ? (
            <div className="col-6 text-end">
              <button className="btn btn-danger fw-bolder rounded-0" type="button" onClick={() => clickHandlerForCrossBtn(index || 0)}>X</button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="card-body">
        {children}
      </div>
    </div>
  );
};

export default EnrollmentCard;
