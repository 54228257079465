import { FC, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { RepriseReimbursementResourcesLogo } from "../../assets";
import { AuthContext } from "../../context/authContext";
import { AuthenticatedRoutes, PublicRoutes, ManufacturerRoutes } from "../../routes/AppRoutes";

const HubSplashScreen: FC = (): JSX.Element => {
  const { isManufacturer, isUserAuthorized, userData, isRestoreCalled, restoreSession } = useContext(AuthContext);
  const navigate = useNavigate();

  const checkForAuthentication = useCallback(() => {
    if (isUserAuthorized) {
      if (isManufacturer) {
        const indexRoute = ManufacturerRoutes.find((route) => route.isIndex);
        if (indexRoute) navigate(indexRoute.route);
      } else {
        console.log(window.location.href)
        if (!window.location.host.includes("baa")) {
          const indexRoute = AuthenticatedRoutes.find((route) => route.isIndex);
          if (indexRoute) navigate(indexRoute.route);
        } else navigate("/");
      }
    } else {
      const indexRoute = PublicRoutes.find((route) => route.isIndex);
      if (indexRoute) navigate(indexRoute.route);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthorized]);

  useEffect(() => {
    if (isRestoreCalled) {
      checkForAuthentication();
    } else if (!userData) {
      restoreSession();
    } else {
      const indexRoute = PublicRoutes.find((route) => route.isIndex);
      if (indexRoute) navigate(indexRoute.route);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkForAuthentication, isRestoreCalled]);

  return (
    <div className="container-fluid row justify-content-center align-items-center pt-4 pb-5" style={{ height: '100vh' }}>
      <div className="col-12 text-center">
        <img height="70px" className="img-fluid mt-3" src={RepriseReimbursementResourcesLogo} alt="Site logo" />

        <div className="mt-2">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubSplashScreen;
