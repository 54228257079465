import { FC, useContext, useEffect, useState, } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import AuthSubHeader from "src/components/authSubHeader";
import CaseInformation from "src/components/caseInformation";
import CaseNotes from "src/components/caseNotes";
import DocumentUpload from "src/components/documentUpload";
import FooterText from "src/components/footerText";
import { CaseNotesType } from "src/components/caseNotes/types";
import InsuranceInformation from "src/components/insuranceInformation";
import PatientInformation from "src/components/patientInformation";
import PrescriberInformation from "src/components/prescriberInformation";

import useFetch from "src/hooks/useFetch";
import { ToastContext } from "src/context/toastContext";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";

import {CaseDetailType} from '../../../src/types/caseDetail';
import { CaseListErrorResponse } from "../dashboard/types";
import { CaseDetailAPIResponseType } from "./types";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastTypes } from "src/types";


const HubCaseDetail: FC = (): JSX.Element => {
  const { caseId } = useParams();
  const { hideLoader } = useContext(LoaderContext);
  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);
  const [caseNotes, setCaseNotes] = useState<CaseNotesType[]>([]);
  const [caseDetail, setCaseDetail]=  useState<CaseDetailType>();
  const navigate = useNavigate();


  const formatPhoneNumber = (phoneNumber: number | string) => {
    const cleaned = (phoneNumber).toString().replace(/\D/g, '');
    if (cleaned.length !== 10) {
      return phoneNumber.toString(); // Return as-is if not valid
    }
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };


  const { data: caseDetailObject } =
    useFetch<CaseDetailAPIResponseType>(APIEndpoints.CASE_DETAIL, {}, { caseId });

  useEffect(() => {
    if (caseDetailObject) {
      hideLoader();
      setCaseNotes(caseDetailObject?.data.case_notes)
      setCaseDetail(caseDetailObject?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseDetailObject]);





  return (
      <div className="bg-light overflow-auto flex-fill">
        <div className="container">
          <div className="mt-4 d-flex align-items-center">
            <h2 className="text-primary m-0">Case# {caseId}</h2>
            <span
                className={`m-0 text-center text-white fs-4 px-4 py-1 ms-3 rounded-2 background-color-${caseDetail && caseDetail.case_information ? caseDetail.case_information.color : 1}`}>
            {caseDetail && caseDetail.case_information ? caseDetail.case_information.status : ''}
          </span>
          </div>

          {caseDetail ? (
              <>
                {/* Case Information */}
                <div className="my-4">
                  <CaseInformation caseObject={caseDetail}/>
                </div>
                {/* Case Notes */}
                <div className="my-4">
                  <CaseNotes caseNotes={caseNotes} setNotes={setCaseNotes} caseObject={caseDetail}/>
                </div>
                {/* Documents */}
                <div className="my-4">
                  <DocumentUpload
                      setNotes={setCaseNotes}
                      caseDocuments={caseDetail.case_documents ? caseDetail.case_documents : []}
                      caseId={caseDetail.case_information ? caseDetail.case_information.case_id : 0}
                  />
                </div>
                {/* Patient Information */}
                <div className="my-4">
                  <PatientInformation caseObject={caseDetail} formatPhoneNumber={formatPhoneNumber}/>
                </div>
                {/* Prescriber Information */}
                <div className="my-4">
                  <PrescriberInformation caseObject={caseDetail} formatPhoneNumber={formatPhoneNumber}/>
                </div>
                {/* Insurance Information */}
                <div className="card m-2">
                  <div className="card-header">
                    <h4 className="text-black m-0">Insurance Information</h4>
                  </div>

                  <div className="card-body">
                    {caseDetail.insurance_information.length > 0 ?
                        (
                            <>
                              {caseDetail.insurance_information.map((item, index) => (
                                  <InsuranceInformation key={index} insuranceNumber={index + 1}
                                                        insuranceInformation={item}
                                                        formatPhoneNumber={formatPhoneNumber}/>
                              ))}
                            </>
                        )
                        : null}
                  </div>
                </div>
              </>
          ) : null}

          <div className="bg-white mt-3"><FooterText footerType="black"/></div>
        </div>
      </div>
  );
};

export default HubCaseDetail;
