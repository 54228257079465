import { FC, useContext } from "react"
import { TextAlignProps } from "../../contactAndSafetyInfo/types";
import ManufacturerContactAndSafetyInfo from "../manufacturerContactAndSafetyInfo";
import { AuthContext } from "src/context/authContext";
import { ManufacturerSubHeaderProps } from "src/types/users";

const AuthMaunfacturerSubHeader: FC = (): JSX.Element => {
  const { sub_header } = useContext(AuthContext);

  return (
    <div className="row align-items-center">
      <div className="col-12 col-md-3 text-left">
        <div className="row">
          {/* <div className="col"> */}
            <p className="text-dark fw-bold ">
              User: <span className="fw-lighter">{sub_header ? (sub_header as ManufacturerSubHeaderProps).User : ''}</span>
            </p>
            <p className="text-dark fw-bold ">
              Territory: <span className="fw-lighter">{sub_header ? (sub_header as ManufacturerSubHeaderProps).Territory : ''}</span>
            </p>
          {/* </div> */}
        </div>
      </div>

      <div className="col-12 col-md-3 text-center">
        <div className="col-12 col-md-3 text-left">
          {/* <p className="mb-0 fw-bold "><span className="fw-bold"></span>User:</p>
          <p><span className="fw-normal"> National</span></p> */}
        </div>
      </div>

      <div className="col-12 col-md-6">
        <ManufacturerContactAndSafetyInfo textColor="primary" alignText={TextAlignProps.TEXT_END} />
      </div>
    </div>
  );
};

export default AuthMaunfacturerSubHeader;
